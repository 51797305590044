.App {
  text-align: center;
}

button {
  font-size: 20px;
}

.Error {
  color: red;
}