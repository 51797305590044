.CoinFlipDemo .Result {
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
}

.CoinFlipDemo .Result img {
  width: 100px;
  height: 100px;
}