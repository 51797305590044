.RollDiceDemo .Result {
  width: 250px;
  height: 70px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
}

.RollDiceDemo .Result img {
  width: 70px;
  height: 70px;
}